import React from "react";
import "./newsletter.css";
import { Link } from "react-router-dom";

const Newsletters = () => {
  return (
    <div>
      {/* November 2024 */}
      <div className="newsletter-section">
        <h5
          className="editorial-heading-text"
          style={{ color: "#085EAB", paddingLeft: "10px" }}
        >
          December 2024
        </h5>
        <div className="newsletter-links">
          <div className="editorial-item1">
            <Link to="/newsletters/December/09-December-2024" target="_blank">
              <div className="link-text">09-December-2024</div>
            </Link>
          </div>
          <div className="editorial-item1">
            <Link to="/newsletters/December/13-December-2024" target="_blank">
              <div className="link-text">13-December-2024</div>
            </Link>
          </div>
        </div>
      </div>
      {/* November 2024 */}
      <div className="newsletter-section">
        <h5
          className="editorial-heading-text"
          style={{ color: "#085EAB", paddingLeft: "10px" }}
        >
          November 2024
        </h5>
        <div className="newsletter-links">
          <div className="editorial-item1">
            <Link to="/newsletters/November/08-November-2024" target="_blank">
              <div className="link-text">08-November-2024</div>
            </Link>
          </div>
          <div className="editorial-item1">
            <Link to="/newsletters/November/15-November-2024" target="_blank">
              <div className="link-text">15-November-2024</div>
            </Link>
          </div>
          <div className="editorial-item1">
            <Link to="/newsletters/November/22-November-2024" target="_blank">
              <div className="link-text">22-November-2024</div>
            </Link>
          </div>
          <div className="editorial-item1">
            <Link to="/newsletters/November/29-November-2024" target="_blank">
              <div className="link-text">29-November-2024</div>
            </Link>
          </div>
        </div>
      </div>
      {/* October 2024 */}
      <div className="newsletter-section">
        <h5
          className="editorial-heading-text"
          style={{ color: "#085EAB", paddingLeft: "10px" }}
        >
          October 2024
        </h5>
        <div className="newsletter-links">
          <div className="editorial-item1">
            <Link to="/newsletters/October/04-October-2024" target="_blank">
              <div className="link-text">04-October-2024</div>
            </Link>
          </div>
          <div className="editorial-item1">
            <Link to="/newsletters/October/09-October-2024" target="_blank">
              <div className="link-text">09-October-2024</div>
            </Link>
          </div>
          <div className="editorial-item1">
            <Link to="/newsletters/October/18-October-2024" target="_blank">
              <div className="link-text">18-October-2024</div>
            </Link>
          </div>
          <div className="editorial-item1">
            <Link to="/newsletters/October/25-October-2024" target="_blank">
              <div className="link-text">25-October-2024</div>
            </Link>
          </div>
          <div className="editorial-item1">
            <Link to="/newsletters/October/30-October-2024" target="_blank">
              <div className="link-text">30-October-2024</div>
            </Link>
          </div>
        </div>
      </div>
      {/* September 2024 */}
      <div className="newsletter-section">
        <h5
          className="editorial-heading-text"
          style={{ color: "#085EAB", paddingLeft: "10px" }}
        >
          September 2024
        </h5>
        <div className="newsletter-links">
          <div className="editorial-item1">
            <Link to="/newsletters/September/06-September-2024" target="_blank">
              <div className="link-text">06-September-2024</div>
            </Link>
          </div>
          <div className="editorial-item1">
            <Link to="/newsletters/September/13-September-2024" target="_blank">
              <div className="link-text">13-September-2024</div>
            </Link>
          </div>
          <div className="editorial-item1">
            <Link to="/newsletters/September/20-September-2024" target="_blank">
              <div className="link-text">20-September-2024</div>
            </Link>
          </div>
          <div className="editorial-item1">
            <Link to="/newsletters/September/27-September-2024" target="_blank">
              <div className="link-text">27-September-2024</div>
            </Link>
          </div>
        </div>
      </div>
      {/* August 2024 */}
      <div className="newsletter-section">
        <h5
          className="editorial-heading-text"
          style={{ color: "#085EAB", paddingLeft: "10px" }}
        >
          August 2024
        </h5>
        <div className="newsletter-links">
          <div className="editorial-item1">
            <Link to="/newsletters/August/02-August-2024" target="_blank">
              <div className="link-text">02-August-2024</div>
            </Link>
          </div>
          <div className="editorial-item1">
            <Link to="/newsletters/August/09-August-2024" target="_blank">
              <div className="link-text">09-August-2024</div>
            </Link>
          </div>
          <div className="editorial-item1">
            <Link to="/newsletters/August/16-August-2024" target="_blank">
              <div className="link-text">16-August-2024</div>
            </Link>
          </div>
          <div className="editorial-item1">
            <Link to="/newsletters/August/23-August-2024" target="_blank">
              <div className="link-text">23-August-2024</div>
            </Link>
          </div>
          <div className="editorial-item1">
            <Link to="/newsletters/August/30-August-2024" target="_blank">
              <div className="link-text">30-August-2024</div>
            </Link>
          </div>
        </div>
      </div>
      {/* July 2024 */}
      <div className="newsletter-section">
        <h5
          className="editorial-heading-text"
          style={{ color: "#085EAB", paddingLeft: "10px" }}
        >
          July 2024
        </h5>
        <div className="newsletter-links">
          <div className="editorial-item1">
            <Link to="/newsletters/July/12-July-2024" target="_blank">
              <div className="link-text">12-July-2024</div>
            </Link>
          </div>
          <div className="editorial-item1">
            <Link to="/newsletters/July/26-July-2024" target="_blank">
              <div className="link-text">26-July-2024</div>
            </Link>
          </div>
        </div>
      </div>

      {/* June 2024 */}
      <div className="newsletter-section">
        <h5
          className="editorial-heading-text"
          style={{ color: "#085EAB", paddingLeft: "10px" }}
        >
          June 2024
        </h5>
        <div className="newsletter-links">
          <div className="editorial-item1">
            <Link to="/newsletters/June/07-June-2024" target="_blank">
              <div className="link-text">07-June-2024</div>
            </Link>
          </div>
          <div className="editorial-item1">
            <Link to="/newsletters/June/14-June-2024" target="_blank">
              <div className="link-text">14-June-2024</div>
            </Link>
          </div>
          <div className="editorial-item1">
            <Link to="/newsletters/June/27-June-2024" target="_blank">
              <div className="link-text">27-June-2024</div>
            </Link>
          </div>
        </div>
      </div>

      {/* May 2024 */}
      <div className="newsletter-section">
        <h5
          className="editorial-heading-text"
          style={{ color: "#085EAB", paddingLeft: "10px" }}
        >
          May 2024
        </h5>
        <div className="newsletter-links">
          <div className="editorial-item1">
            <Link to="/newsletters/May/05-May-2024" target="_blank">
              <div className="link-text">05-May-2024</div>
            </Link>
          </div>
          <div className="editorial-item1">
            <Link to="/newsletters/May/17-May-2024" target="_blank">
              <div className="link-text">17-May-2024</div>
            </Link>
          </div>
          <div className="editorial-item1">
            <Link to="/newsletters/May/24-May-2024" target="_blank">
              <div className="link-text">24-May-2024</div>
            </Link>
          </div>
          <div className="editorial-item1">
            <Link to="/newsletters/May/31-May-2024" target="_blank">
              <div className="link-text">31-May-2024</div>
            </Link>
          </div>
        </div>
      </div>

      {/* April 2024 */}
      <div className="newsletter-section">
        <h5
          className="editorial-heading-text"
          style={{ color: "#085EAB", paddingLeft: "10px" }}
        >
          April 2024
        </h5>
        <div className="newsletter-links">
          <div className="editorial-item1">
            <Link to="/newsletters/April/19-April-2024" target="_blank">
              <div className="link-text">19-April-2024</div>
            </Link>
          </div>
          <div className="editorial-item1">
            <Link to="/newsletters/April/26-April-2024" target="_blank">
              <div className="link-text">26-April-2024</div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Newsletters;
