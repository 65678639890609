const blankImg = require("../assets/home-page-assets/Post-assets/sample_chemical.png");

const posts = [
    {
        url: blankImg,
        heading: "Image One",
        description: "This description for Image One explains its context, the story behind the photograph, and its significance in the broader collection."
    },
    {
        url: blankImg,
        heading: "Image Two",
        description: "Image Two's description focuses on the details of the composition, the colors used, and the emotions it aims to evoke in the viewer."
    },
    {
        url: blankImg,
        heading: "Image Three",
        description: "The third image showcases a stunning landscape, and its description elaborates on the location, time of day, and the photographer's vision."
    },
    {
        url: blankImg,
        heading: "Image Four",
        description: "Image Four captures an urban scene. The description discusses the urban elements present, the mood of the setting, and the moment captured."
    },
    {
        url: blankImg,
        heading: "Image Five",
        description: "This image is a close-up shot, and the description delves into the finer details of the subject, the texture, and the play of light."
    },
    {
        url: blankImg,
        heading: "Image Six",
        description: "Image Six is an abstract piece, and its description interprets the abstract elements, the inspiration behind it, and its interpretive nature."
    },
    // Add more post objects as needed
];

module.exports = posts;
