import React from "react";
import "./About.css"; // Importing the CSS file for styling

const About = () => {
  return (
    <div className="about-container">
      <h1>Chemical Today</h1>
      <p style={{ fontWeight: "bold" }}>About Us</p>
      <p>
        worldofchemicals.com extended its outreach by launching the media arm,
        Chemical Today Magazine, the only B2B digital magazine which brings the
        latest updates in the field of chemistry and chemical industry across
        the globe.
      </p>
      <p>
        The Chemical Today magazine is targeted towards chemical industry
        professionals from SMEs to OEMs. It covers over 50 chemical sectors
        including Agriculture, Pharmaceutical, Oil & Gas, Construction, Plastics
        & Polymers, Textiles, Pulp & Paper, among others.
      </p>
      <p>
        The magazine brings out the latest industry news, market studies,
        technology updates, research, industry analysis and developments
        happening in these sectors. It showcases a key focus on sustainable and
        circular solutions, digitization, IIOT, logistics and green chemistry
        which is imperative for the industry's growth in current times. Chemical
        Today Magazine also brings forth business trends and management mantras
        in its interviews with well-recognised industry leaders of major
        organisations.
      </p>
      <p>
        Worldofchemicals.com and Chemical Today platform also hosts various
        virtual conferences, roundtables and seminars on a wide gamut of topics
        in various industries and connects the chemical industry with the
        experts in the field.
      </p>
      <h3 style={{ color: "#085EAB" }}>Our Vision:</h3>
      <p>
        To empower SMEs, MSMEs, and startups in the chemical industry by
        providing them with global visibility and a robust platform for growth.
        We aim to connect industry professionals, foster knowledge sharing, and
        highlight the latest innovations and research from around the world. By
        producing a high-quality monthly digital magazine, partnering with key
        industry events, and curating thought leadership content, we strive to
        be the go-to resource for sector insights, technological advancements,
        and networking opportunities.
        {/* <li>Give brand visibility to SMEs, MSMEs and start-ups and provide with the necessary global exposure</li>
                <li>Reach out to the chemical industry professionals and keep them updated with the latest news, technology and innovation on a daily basis</li>
                <li>Bring in the best-in-class interactions with industry leaders from across the globe</li>
                <li>Give a platform to researchers, academicians and young scientists across the globe and showcase their research papers and academic findings in the magazine</li>
                <li>Bring out 80-pages digital issue of the magazine on a monthly basis featuring sector views, experts articles, technology updates, green chemistry, digitization in chemical industry, etc.</li>
                <li>Partner with global industrial events to increase our outreach</li>
                <li>Host more events jointly with worldofchemicals.com and reach out to more chemical sectors. All-in-all it looks to enhance knowledge sharing and networking in the chemical marketplace</li>
                <li>Curating whitepapers featuring the views of the top leaders and subject experts on a wide range of topics from the chemical industry</li>
                <li>Creating podcasts, video interviews and facility visits that will be featured on Chemical Today TV and various social media platforms</li> */}
      </p>
      <h3 style={{ color: "#085EAB" }}>Our Mission:</h3>
      <p>
        Our mission is to deliver accurate, timely information through a
        cutting-edge, user-friendly platform managed by a dedicated,
        women-centric media team. We are committed to enhancing the visibility
        of our content across social media and other channels, providing value
        to our audience with reliable data, and facilitating meaningful
        engagement within the chemical industry through events, podcasts, and
        multimedia initiatives.
        {/* <li>Upgrading our platform with latest technology and making it user-friendly</li>
                <li>Well trained women centric media team working actively to bring out the latest news and updates of the industry</li>
                <li>Providing accuracy of information and data on our platform</li>
                <li>Play an active role on social media platform for better visibility and outreach of our operations</li> */}
      </p>
    </div>
  );
};

export default About;
