import React from "react";
import "../about/About.css";
import "./term.css";
const TermsAndConditions = () => {
  return (
    <div className="about-container">
      <h3 style={{ color: "#085EAB" }}>
        <strong>General Terms of Use</strong>
      </h3>
      <ol>
        <li>
          <h4 className="heading-5-p">Your Agreement with Chemical Today</h4>
          <ul>
            <li>
              <p>
                <strong>1.1 Choice of Law:</strong> Any legal conflict will be
                governed under the Indian Law.
              </p>
            </li>
            <li>
              <p>
                <strong>1.2 Additional Terms:</strong> Our Services and Software
                are subscribed, not sold, to you, and may also be subject to one
                or more of the additional terms below ("Additional Terms"). If
                there is any conflict between the terms in the General Terms and
                the Additional Terms, then the Additional Terms govern in
                relation to that Service or Software. The Additional Terms are
                subject to change.
              </p>
            </li>
          </ul>
        </li>
        <li>
          <h4 className="heading-5-p">Privacy</h4>
          <ul>
            <li>
              <p>
                <strong>2.1 Privacy:</strong> Please note that in order to use
                our apps and websites, you authorize Chemical Today to transfer
                your personal information across national borders and to other
                countries where Chemical Today and its partners operate.
              </p>
            </li>
            <li>
              <p>
                We provide interactive features that engage with social media
                sites, such as Facebook. If you use these features, these sites
                will send us personal information about you.
              </p>
            </li>
            <li>
              <p>
                We use cookies on Chemical Today to make it more user friendly,
                personal and informative. The cookies that we use on our website
                do not harm your system or collect personally identifiable
                information.
              </p>
            </li>
          </ul>
        </li>
        <li>
          <h4 className="heading-5-p">Use of Services and Software</h4>
          <ul>
            <li>
              <p>
                <strong>3.1 License:</strong> Subject to your compliance with
                the Terms and the law, you may access and use the Services and
                Software.
              </p>
            </li>
          </ul>
        </li>
        <li>
          <h4 className="heading-5-p">Your Content</h4>
          <ul>
            <li>
              <p>
                <strong>4.1 Content:</strong> "Content" means any material, such
                as electronic documents, or images, that you upload and import
                into the Services or Software in connection with your use of the
                Services.
              </p>
            </li>
            <li>
              <p>
                <strong>4.2 Ownership:</strong> You retain all rights and
                ownership of your content. We do not claim any ownership rights
                to your content.
              </p>
            </li>
          </ul>
        </li>
        <li>
          <h4 className="heading-5-p">Account Information</h4>
          <p>
            You are responsible for all activity that occurs via your account.
            Please notify Customer Support immediately if you become aware of
            any unauthorized use of your account.
          </p>
        </li>
        <li>
          <h4 className="heading-5-p">User Conduct</h4>
          <ul>
            <li>
              <p>
                <strong>6.1 Responsible Use:</strong> You must use the Services
                and Software responsibly.
              </p>
            </li>
            <li>
              <p>
                <strong>6.2 Misuse:</strong> You must not misuse the Services or
                Software. For example, you must not copy, modify, host, stream,
                sublicense, or resell the Services or Software.
              </p>
            </li>
          </ul>
        </li>
        <li>
          <h4 className="heading-5-p">Fees and Payment</h4>
          <ul>
            <li>
              <p>
                <strong>7.1 Taxes and Third-Party Fees:</strong> You must pay
                any applicable taxes and third-party fees. We are not
                responsible for these fees.
              </p>
            </li>
          </ul>
        </li>
        <li>
          <h4 className="heading-5-p">Termination</h4>
          <ul>
            <li>
              <p>
                <strong>8.1 Termination by You:</strong> You may stop using the
                Services and Software at any time. The amount paid towards any
                services will not be refunded by us when you opt out your own.
              </p>
            </li>
          </ul>
        </li>
        <li>
          <h4 className="heading-5-p">Dispute Resolution</h4>
          <p>
            <strong>9.1 Process:</strong> If you have any concern or dispute,
            you agree to first try to resolve the dispute informally by
            contacting us.
          </p>
        </li>
        <li>
          <h4 className="heading-5-p">Updates and Availability</h4>
          <ul>
            <li>
              <p>
                <strong>
                  10.1 Updates to the General Terms and Additional Terms:
                </strong>{" "}
                We may modify these General Terms, any Additional Terms, for
                example, to reflect changes to the law or changes to our
                Services or Software.
              </p>
            </li>
          </ul>
        </li>
      </ol>
    </div>
  );
};

export default TermsAndConditions;
