import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./events.css";
import hb from "../../assets/events/hrs-1.jpg";
import corrosion from "../../assets/events/corrosion.png";

import eventimage1 from "../../assets/events/400-x-200-GRPC-LOGO.jpg";
import eventimage3 from "../../assets/events/400X200 pix-300dpi rwe logo.jpeg";
import eventadvt2 from "../../assets/events/RWE Ads-13 2.jpg";
import eventimage4 from "../../assets/events/India Chem 2024_400 x 200 pixels Logo 2.jpg";
import eventimage5 from "../../assets/events/400x200-TURKCHEM.jpg";
// import eventimage6 from "../../assets/events/DSS Banner  (400 x 200 px).png";
// import eventimage7 from "../../assets/events/IBAAS-2024.jpg";
// import eventimage8 from "../../assets/events/SEPAWA24.jpg";
// import eventimage9 from "../../assets/events/MP-Banner.jpg";
// import eventimage10 from "../../assets/events/IPCC24.png";
// import eventimage11 from "../../assets/events/plastics.png";
// import eventimage12 from "../../assets/events/packaging.png";
import eventimage13 from "../../assets/events/CPHI & PMEC India 2024.jpg";
// import eventimage14 from "../../assets/events/Re-source.jpg";
// import eventimage15 from "../../assets/events/PM&AE Awards 2024.png";
// import eventimage16 from "../../assets/events/PM&AC2024.png";
import eventimage17 from "../../assets/events/IDS25.png";
import eventimage18 from "../../assets/events/IRS_2024.jpg";
import eventimage19 from "../../assets/events/event19.jpg";
import eventimage20 from "../../assets/events/event20.jpg";
import eventimage24 from "../../assets/events/event24.png";
import eventimage21 from "../../assets/events/event21.jpg";
import eventimage22 from "../../assets/events/event22.jpg";
import eventimage23 from "../../assets/events/event23.jpg";
import eventimage25 from "../../assets/events/event25.png";
import eventimage26 from "../../assets/events/event26.jpg";
import eventimage27 from "../../assets/events/event27.jpg";
import eventimage28 from "../../assets/events/28.jpg";
import eventimage29 from "../../assets/events/event29.jpg";
import eventimage30 from "../../assets/events/event30.png";

import oilandcorrosion from "../../assets/events/corrosion-oil-and-gas.jpg";
import airpollution from "../../assets/events/airpollution.jpg";
import cosmolnew from "../../assets/events/comsol-24.jpeg";
import oilgas from "../../assets/events/oil&gas.jpg";

import airpollution2 from "../../assets/events/airpollution2.jpg";
import supplychainmanagement from "../../assets/events/Supply-Chain-Management.png";
import pharmaeventimage from "../../assets/events/Pharma-Event-Image.jpg";
import pharmaceutical from "../../assets/events/pharmaceutical-industry.png";

import oilandgas from "../../assets/events/Oil And Gas Image.jpg";
import sustainability_fs from "../../assets/events/Sustainability-FS-Image.png";
import corrosion_tech_forum from "../../assets/events/corrosion_tech_forum.jpg";
import chemical4o from "../../assets/events/Chemical 4.0.jpg";
import adipec from "../../assets/events/ADIPEC_2024_484x252[1].jpg";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

const Events = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  useEffect(() => {
    window.scrollTo({ top: 100 });
  }, []);
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
  };
  return (
    <div style={{ fontFamily: "Helvetica" }}>
      <div style={{ width: "90%", margin: "auto" }}>
        <Slider {...settings}>
          <a
            target="_blank"
            style={{ textDecoration: "none" }}
            href="https://www.adipec.com/"
          >
            <div>
              <img
                src={hb}
                alt="First Image"
                style={{ width: "100%", display: "block" }}
              />
            </div>
          </a>
          <a
            target="_blank"
            style={{ textDecoration: "none" }}
            href="https://reactorworldexpo.com/"
          >
            <div>
              <img
                src={eventadvt2}
                alt="Second Image"
                style={{ width: "100%", display: "block" }}
              />
            </div>
          </a>
        </Slider>
      </div>
      <div style={{ marginRight: "5%", marginLeft: "5%", marginTop: "40px" }}>
        {/* <div className="cover-post-heading">
          <h3 className="cover-post-heading-text">Upcoming Webinars</h3>
        </div> */}

        <div className="cover-post-items-events"></div>

        {/* ------------------------------------------ */}

        <div className="cover-post-heading">
          <h3 className="cover-post-heading-text">Past Webinars</h3>
        </div>

        <div className="cover-post-items-events">
          <Link
            key={1}
            to={`https://events.chemicaltoday.in/webinar/Modeling_Corrosion_and_Corrosion_Protection_Systems_with_COMSOL_Multiphysics`}
            target="_blank"
            className="news-card"
            style={{ height: "300px", width: "30%", marginBottom: "20px" }}
          >
            <img
              src={corrosion}
              alt="Oil and Gas"
              className="post-card-image"
            />
            <div className="img-text">
              <p className="text">November 28, 2024 </p>
            </div>
            <p
              className="overlay-text"
              style={{
                marginLeft: "8px",
                fontWeight: "bold",
                fontFamily: "Helvitica",
              }}
            >
              Modeling Corrosion and Corrosion Protection Systems with COMSOL
              Multiphysics
            </p>
          </Link>
          <Link
            key={1}
            to={`https://events.chemicaltoday.in/webinar/Multiphysics_Simulation_for_Oil_and_Gas_Processing/`}
            target="_blank"
            className="news-card"
            style={{ height: "300px", width: "30%", marginBottom: "20px" }}
          >
            <img src={oilgas} alt="Oil and Gas" className="post-card-image" />
            <div className="img-text">
              <p className="text">July 19, 2024 </p>
            </div>
            <p
              className="overlay-text"
              style={{
                marginLeft: "8px",
                fontWeight: "bold",
                fontFamily: "Helvitica",
              }}
            >
              Multiphysics Simulation for Oil & Gas Processing
            </p>
          </Link>
          <Link
            key={1}
            to={`https://events.chemicaltoday.in/webinar/Modeling_Pharmaceutical_Processes_with_COMSOL_Multiphysics`}
            target="_blank"
            className="news-card"
            style={{ height: "300px", width: "30%", marginBottom: "20px" }}
          >
            <img
              src={cosmolnew}
              alt="oil and corrosion"
              className="post-card-image"
            />
            <div className="img-text">
              <p className="text">May 29, 2024 </p>
            </div>
            <p
              className="overlay-text"
              style={{
                marginLeft: "8px",
                fontWeight: "bold",
                fontFamily: "Helvitica",
              }}
            >
              Modeling Pharmaceutical Processes with COMSOL Multiphysics
            </p>
          </Link>

          <Link
            key={1}
            to={`https://events.chemicaltoday.in/comsol`}
            target="_blank"
            className="news-card"
            style={{ height: "300px", width: "30%", marginBottom: "20px" }}
          >
            <img
              src={oilandcorrosion}
              alt="oil and corrosion"
              className="post-card-image"
            />
            <div className="img-text">
              <p className="text">Mar 10, 2023 </p>
            </div>
            <p
              className="overlay-text"
              style={{
                marginLeft: "8px",
                fontWeight: "bold",
                fontFamily: "Helvitica",
              }}
            >
              Simulating Corrosion and Protection For Oil and Gas Equipment
            </p>
          </Link>
          <Link
            key={1}
            to={`https://events.chemicaltoday.in/airpollutioncontrol2/`}
            target="_blank"
            className="news-card"
            style={{ height: "300px", width: "30%", marginBottom: "20px" }}
          >
            <img
              src={airpollution}
              alt="Air Pollution"
              className="post-card-image"
            />
            <div className="img-text">
              <p className="text">July 8, 2022 </p>
            </div>
            <p
              className="overlay-text"
              style={{
                marginLeft: "8px",
                fontWeight: "bold",
                fontFamily: "Helvitica",
              }}
            >
              Exhaust Air Pollution Control Technologies.
            </p>
          </Link>
          <Link
            key={1}
            to={`https://events.chemicaltoday.in/airpollutioncontrol/`}
            target="_blank"
            className="news-card"
            style={{ height: "300px", width: "30%", marginBottom: "20px" }}
          >
            <img
              src={airpollution2}
              alt="Air Pollution2"
              className="post-card-image"
            />
            <div className="img-text">
              <p className="text">July 2, 2021 </p>
            </div>
            <p
              className="overlay-text"
              style={{
                marginLeft: "8px",
                fontWeight: "bold",
                fontFamily: "Helvitica",
              }}
            >
              Exhaust Air Pollution Control Technologies.
            </p>
          </Link>
        </div>
        <div className="cover-post-heading">
          <h3 className="cover-post-heading-text">Past Events</h3>
        </div>
        <div className="cover-post-items-events">
          <Link
            key={1}
            to={`https://events.chemicaltoday.in/Pharma/`}
            target="_blank"
            className="news-card"
            style={{ height: "300px", width: "30%", marginBottom: "20px" }}
          >
            <img
              src={pharmaceutical}
              alt="Pharmaceutical Industry"
              className="post-card-image"
            />
            <div className="img-text">
              <p className="text">Aug 12, 2022 </p>
            </div>
            <p
              className="overlay-text"
              style={{
                marginLeft: "8px",
                fontWeight: "bold",
                fontFamily: "Helvitica",
              }}
            >
              Pharma Connext: Formulating The Future.
            </p>
          </Link>

          <Link
            key={1}
            to={`https://events.chemicaltoday.in/SOLVENTSPHARMACOPEIA/`}
            target="_blank"
            className="news-card"
            style={{ height: "300px", width: "30%", marginBottom: "20px" }}
          >
            <img
              src={pharmaeventimage}
              alt="Solvents Pharmacopeia"
              className="post-card-image"
            />
            <div className="img-text">
              <p className="text">Sep 29, 2022 </p>
            </div>
            <p
              className="overlay-text"
              style={{
                marginLeft: "8px",
                fontWeight: "bold",
                fontFamily: "Helvitica",
              }}
            >
              Solvents Pharmacopeia
            </p>
          </Link>

          <Link
            key={1}
            to={`https://events.chemicaltoday.in/supplychainlogistics/`}
            target="_blank"
            className="news-card"
            style={{ height: "300px", width: "30%", marginBottom: "20px" }}
          >
            <img
              src={supplychainmanagement}
              alt="Supply Chain & Logistics"
              className="post-card-image"
            />
            <div className="img-text">
              <p className="text">Nov 11, 2022 </p>
            </div>
            <p
              className="overlay-text"
              style={{
                marginLeft: "8px",
                fontWeight: "bold",
                fontFamily: "Helvitica",
              }}
            >
              Chemicals in Tansit 2022
            </p>
          </Link>

          <Link
            key={1}
            to={`https://events.chemicaltoday.in/OPRSUMMIT2022/`}
            target="_blank"
            className="news-card"
            style={{ height: "300px", width: "30%", marginBottom: "20px" }}
          >
            <img
              src={oilandgas}
              alt="Oil And Gas Image"
              className="post-card-image"
            />
            <div className="img-text">
              <p className="text">June 17, 2022 </p>
            </div>
            <p
              className="overlay-text"
              style={{
                marginLeft: "8px",
                fontWeight: "bold",
                fontFamily: "Helvitica",
              }}
            >
              OPR Summit 2022
            </p>
          </Link>

          <Link
            key={1}
            to={`https://events.chemicaltoday.in/sustainabilty-circularitysummit2022/`}
            target="_blank"
            className="news-card"
            style={{ height: "300px", width: "30%", marginBottom: "20px" }}
          >
            <img
              src={sustainability_fs}
              alt="Sustainability & Circular Economy For Plastics."
              className="post-card-image"
            />
            <div className="img-text">
              <p className="text">April 8, 2022 </p>
            </div>
            <p
              className="overlay-text"
              style={{
                marginLeft: "8px",
                fontWeight: "bold",
                fontFamily: "Helvitica",
              }}
            >
              Sustainability Circularity Summit 2022
            </p>
          </Link>

          <Link
            key={1}
            to={`https://events.chemicaltoday.in/chemical4conference/`}
            target="_blank"
            className="news-card"
            style={{ height: "300px", width: "30%", marginBottom: "20px" }}
          >
            <img
              src={chemical4o}
              alt="Chemical 4.0 Automating The Future."
              className="post-card-image"
            />
            <div className="img-text">
              <p className="text">July 16, 2021 </p>
            </div>
            <p
              className="overlay-text"
              style={{
                marginLeft: "8px",
                fontWeight: "bold",
                fontFamily: "Helvitica",
              }}
            >
              Chemical 4.0 Automating The Future.
            </p>
          </Link>
        </div>

        <div className="cover-post-heading">
          <h3 className="cover-post-heading-text">Conference</h3>
        </div>

        <div className="cover-post-items-events">
          <Link
            key={1}
            className="news-card"
            style={{ height: "300px", width: "30%", marginBottom: "20px" }}
          >
            <img
              src={corrosion_tech_forum}
              alt="corrosion"
              className="post-card-image"
            />
            <div className="img-text">
              <p className="text">june 7-8, 2018 </p>
            </div>
            <p
              className="overlay-text"
              style={{
                marginLeft: "8px",
                fontWeight: "bold",
                fontFamily: "Helvitica",
              }}
            >
              Corrosion Technology Forum 2018
            </p>
          </Link>
        </div>

        <div className="cover-post-heading">
          <h3 className="cover-post-heading-text">Industrial Events</h3>
        </div>
        <div className="cover-post-items-events">
          {/* ------------------------------- */}

          {/* event 25 */}
          {/* <Link
            key={1}
            to={`/industrial_events/Sustainable_Action_Conference_2024(2.0)`}
            target="_blank"
            className="news-card"
            style={{ height: "300px", width: "30%", marginBottom: "20px" }}
          >
            <img
              src={eventimage25}
              alt="Sustainable Action Conference 2024 (2.0)"
              className="post-card-image"
            />
            <div className="img-text">
              <p className="text">November 21, 2024 </p>
            </div>
            <p
              className="overlay-text"
              style={{
                marginLeft: "8px",
                fontWeight: "bold",
                fontFamily: "Helvitica",
              }}
            >
              Sustainable Action Conference 2024 (2.0)
            </p>
          </Link> */}
          {/* <Link
            key={1}
            to={`/industrial_events/The_3rd_Asia_Green_Packaging_Innovation_Summit_2024`}
            target="_blank"
            className="news-card"
            style={{ height: "300px", width: "30%", marginBottom: "20px" }}
          >
            <img
              src={eventimage12}
              alt="The 3rd Asia Green Packaging Innovation Summit 2024"
              className="post-card-image"
            />
            <div className="img-text">
              <p className="text">November 21-22, 2024</p>
            </div>
            <p
              className="overlay-text"
              style={{
                marginLeft: "8px",
                fontWeight: "bold",
                fontFamily: "Helvitica",
              }}
            >
              The 3rd Asia Green Packaging Innovation Summit 2024
            </p>
          </Link> */}
          {/* <Link
            key={1}
            to={`/industrial_events/CPHI_&_PMEC_India_2024`}
            target="_blank"
            className="news-card"
            style={{ height: "300px", width: "30%", marginBottom: "20px" }}
          >
            <img
              src={eventimage13}
              alt="CPHI & PMEC India 2024"
              className="post-card-image"
            />
            <div className="img-text">
              <p className="text">November 26-28, 2024</p>
            </div>
            <p
              className="overlay-text"
              style={{
                marginLeft: "8px",
                fontWeight: "bold",
                fontFamily: "Helvitica",
              }}
            >
              CPHI & PMEC India 2024
            </p>
          </Link> */}
          {/* <Link
            key={2}
            to={"/industrial_events/Turkchem_2024"}
            target="_blank"
            className="news-card"
            style={{ height: "300px", width: "30%", marginBottom: "20px" }}
          >
            <img
              src={eventimage5}
              alt="Turkchem 2024"
              className="post-card-image"
            />
            <div className="img-text">
              <p className="text">November 27-29, 2024 </p>
            </div>
            <p
              className="overlay-text"
              style={{
                marginLeft: "8px",
                fontWeight: "bold",
                fontFamily: "Helvitica",
              }}
            >
              Turkchem 2024{" "}
            </p>
          </Link>
          <Link
            key={2}
            to={
              "/industrial_events/Rethink_Circularity:_European_Circular_Economy_Summit_2024"
            }
            target="_blank"
            className="news-card"
            style={{ height: "300px", width: "30%", marginBottom: "20px" }}
          >
            <img
              src={eventimage24}
              alt="Turkchem 2024"
              className="post-card-image"
            />
            <div className="img-text">
              <p className="text">December 5-6, 2024</p>
            </div>
            <p
              className="overlay-text"
              style={{
                marginLeft: "8px",
                fontWeight: "bold",
                fontFamily: "Helvitica",
              }}
            >
              Rethink Circularity: European Circular Economy Summit 2024
            </p>
          </Link> */}
          {/* new event added on 7th november */}
          {/* <Link
            key={2}
            to={"/industrial_events/ICC_Sustainability_Conclave_2024"}
            target="_blank"
            className="news-card"
            style={{ height: "300px", width: "30%", marginBottom: "20px" }}
          >
            <img
              src={eventimage26}
              alt="Turkchem 2024"
              className="post-card-image"
            />
            <div className="img-text">
              <p className="text">December 5-6, 2024</p>
            </div>
            <p
              className="overlay-text"
              style={{
                marginLeft: "8px",
                fontWeight: "bold",
                fontFamily: "Helvitica",
              }}
            >
              ICC Sustainability Conclave 2024
            </p>
          </Link> */}
          {/* event 30 */}
          <Link
            key={2}
            to={"/industrial_events/PharmaTech_&_LabTech_Expo_2025"}
            target="_blank"
            className="news-card"
            style={{ height: "300px", width: "30%", marginBottom: "20px" }}
          >
            <img
              src={eventimage30}
              alt="PharmaTech & LabTech Expo 2025"
              className="post-card-image"
            />
            <div className="img-text">
              <p className="text">January 08-10, 2025</p>
            </div>
            <p
              className="overlay-text"
              style={{
                marginLeft: "8px",
                fontWeight: "bold",
                fontFamily: "Helvitica",
              }}
            >
              PharmaTech & LabTech Expo 2025
            </p>
          </Link>
          {/* .......end.......... */}
          <Link
            key={2}
            to={"/industrial_events/Reactor_World_Expo"}
            target="_blank"
            className="news-card"
            style={{ height: "300px", width: "30%", marginBottom: "20px" }}
          >
            <img
              src={eventimage3}
              alt="Reactor_World_Expo"
              className="post-card-image"
            />
            <div className="img-text">
              <p className="text">January 9-11, 2025 </p>
            </div>
            <p
              className="overlay-text"
              style={{
                marginLeft: "8px",
                fontWeight: "bold",
                fontFamily: "Helvitica",
              }}
            >
              Reactor World Expo
            </p>
          </Link>
          {/* 18-09-2024 */}
          <Link
            key={2}
            to={
              "/industrial_events/Industrial_Decarbonization_Summit_Road_to_Net_Zero(IDS)"
            }
            target="_blank"
            className="news-card"
            style={{ height: "300px", width: "30%", marginBottom: "20px" }}
          >
            <img
              src={eventimage17}
              alt="Industrial_Decarbonization_Summit_Road_to_Net_Zero(IDS)"
              className="post-card-image"
            />
            <div className="img-text">
              <p className="text">January 15, 2025 </p>
            </div>
            <p
              className="overlay-text"
              style={{
                marginLeft: "8px",
                fontWeight: "bold",
                fontFamily: "Helvitica",
              }}
            >
              Industrial Decarbonization Summit - Road to Net Zero(IDS)
            </p>
          </Link>
          {/* 15-11-2024 - event 28*/}
          <Link
            key={2}
            to={
              "/industrial_events/PMFAI_ICSCE_-_Trade_Show_Exhibition_and_Conference"
            }
            target="_blank"
            className="news-card"
            style={{ height: "300px", width: "30%", marginBottom: "20px" }}
          >
            <img
              src={eventimage28}
              alt="Industrial_Decarbonization_Summit_Road_to_Net_Zero(IDS)"
              className="post-card-image"
            />
            <div className="img-text">
              <p className="text">January 21-22, 2025 </p>
            </div>
            <p
              className="overlay-text"
              style={{
                marginLeft: "8px",
                fontWeight: "bold",
                fontFamily: "Helvitica",
              }}
            >
              PMFAI ICSCE- Trade Show Exhibition and conference
            </p>
          </Link>
          {/* 20-11-2024 - event 29*/}
          <Link
            key={2}
            to={"/industrial_events/Drug_Safety_Symposium_2025_-_Dubai_Chapter"}
            target="_blank"
            className="news-card"
            style={{ height: "300px", width: "30%", marginBottom: "20px" }}
          >
            <img
              src={eventimage29}
              alt="Drug Safety Symposium 2025 - Dubai Chapter"
              className="post-card-image"
            />
            <div className="img-text">
              <p className="text">February 12-13, 2025 </p>
            </div>
            <p
              className="overlay-text"
              style={{
                marginLeft: "8px",
                fontWeight: "bold",
                fontFamily: "Helvitica",
              }}
            >
              Drug Safety Symposium 2025 - Dubai Chapter
            </p>
          </Link>
          {/* event 19 */}
          <Link
            key={2}
            to={"/industrial_events/CII’s_Water_&_Waste_Expo_2025"}
            target="_blank"
            className="news-card"
            style={{ height: "300px", width: "30%", marginBottom: "20px" }}
          >
            <img
              src={eventimage19}
              alt="CII’s Water & Waste Expo 2025"
              className="post-card-image"
            />
            <div className="img-text">
              <p className="text">February 20-22, 2025 </p>
            </div>
            <p
              className="overlay-text"
              style={{
                marginLeft: "8px",
                fontWeight: "bold",
                fontFamily: "Helvitica",
              }}
            >
              CII’s Water & Waste Expo 2025
            </p>
          </Link>
          {/* event 27 */}
          <Link
            key={2}
            to={"/industrial_events/Plastasia_2025"}
            target="_blank"
            className="news-card"
            style={{ height: "300px", width: "30%", marginBottom: "20px" }}
          >
            <img
              src={eventimage27}
              alt="Plastasia 2025"
              className="post-card-image"
            />
            <div className="img-text">
              <p className="text">May 09–12, 2025 </p>
            </div>
            <p
              className="overlay-text"
              style={{
                marginLeft: "8px",
                fontWeight: "bold",
                fontFamily: "Helvitica",
              }}
            >
              Plastasia 2025
            </p>
          </Link>
          {/* event 21 */}
          <Link
            key={2}
            to={"/industrial_events/INACOATING_2025"}
            target="_blank"
            className="news-card"
            style={{ height: "300px", width: "30%", marginBottom: "20px" }}
          >
            <img
              src={eventimage21}
              alt="CII’s Water & Waste Expo 2025"
              className="post-card-image"
            />
            <div className="img-text">
              <p className="text">July 29 –31, 2025 </p>
            </div>
            <p
              className="overlay-text"
              style={{
                marginLeft: "8px",
                fontWeight: "bold",
                fontFamily: "Helvitica",
              }}
            >
              INACOATING 2025
            </p>
          </Link>
          {/* event 22 */}
          <Link
            key={2}
            to={"/industrial_events/INAGRICHEM_2025"}
            target="_blank"
            className="news-card"
            style={{ height: "300px", width: "30%", marginBottom: "20px" }}
          >
            <img
              src={eventimage22}
              alt="INAGRICHEM 2025"
              className="post-card-image"
            />
            <div className="img-text">
              <p className="text">July 29– 31, 2025</p>
            </div>
            <p
              className="overlay-text"
              style={{
                marginLeft: "8px",
                fontWeight: "bold",
                fontFamily: "Helvitica",
              }}
            >
              INAGRICHEM 2025
            </p>
          </Link>
          {/* event 23 */}
          <Link
            key={2}
            to={"/industrial_events/CHEMICAL_INDONESIA_2025"}
            target="_blank"
            className="news-card"
            style={{ height: "300px", width: "30%", marginBottom: "20px" }}
          >
            <img
              src={eventimage23}
              alt="CHEMICAL INDONESIA 2025"
              className="post-card-image"
            />
            <div className="img-text">
              <p className="text">July 29–31, 2025</p>
            </div>
            <p
              className="overlay-text"
              style={{
                marginLeft: "8px",
                fontWeight: "bold",
                fontFamily: "Helvitica",
              }}
            >
              CHEMICAL INDONESIA 2025
            </p>
          </Link>

          {/* <Link
            key={2}
            to={"/industrial_events/Drug_Safety_Symposium_2024"}
            target="_blank"
            className="news-card"
            style={{ height: "300px", width: "30%", marginBottom: "20px" }}
          >
            <img
              src={eventimage6}
              alt="India_Chem_2024"
              className="post-card-image"
            />
            <div className="img-text">
              <p className="text">June 27-28, 2024 </p>
            </div>
            <p
              className="overlay-text"
              style={{
                marginLeft: "8px",
                fontWeight: "bold",
                fontFamily: "Helvitica",
              }}
            >
              Drug Safety Symposium 2024 - India Chapter
            </p>
          </Link> */}
          {/* <Link
            key={2}
            to={
              "/industrial_events/Global_Refining__and_Petrochemicals_Congress"
            }
            target="_blank"
            className="news-card"
            style={{ height: "300px", width: "30%", marginBottom: "20px" }}
          >
            <img
              src={eventimage1}
              alt="Global Refining & Petrochemicals Congress"
              className="post-card-image"
            />
            <div className="img-text">
              <p className="text">June 27-28, 2024 </p>
            </div>
            <p
              className="overlay-text"
              style={{
                marginLeft: "8px",
                fontWeight: "bold",
                fontFamily: "Helvitica",
              }}
            >
              Global Refining & Petrochemicals Congress
            </p>
          </Link> */}
          {/* <Link
            key={2}
            to={"/industrial_events/Industrial_Pollution_Control_Congress_2024"}
            target="_blank"
            className="news-card"
            style={{ height: "300px", width: "30%", marginBottom: "20px" }}
          >
            <img
              src={eventimage10}
              alt="Industrial Pollution Control Congress 2024"
              className="post-card-image"
            />
            <div className="img-text">
              <p className="text">September 2, 2024 </p>
            </div>
            <p
              className="overlay-text"
              style={{
                marginLeft: "8px",
                fontWeight: "bold",
                fontFamily: "Helvitica",
              }}
            >
              Industrial Pollution Control Congress 2024
            </p>
          </Link> */}
          {/* <Link
            key={2}
            to={"/industrial_events/IBAAS_IIM_2024_Conference_&_Exhibition"}
            target="_blank"
            className="news-card"
            style={{ height: "300px", width: "30%", marginBottom: "20px" }}
          >
            <img
              src={eventimage7}
              alt="IBAAS-IIM 2024 Conference & Exhibition"
              className="post-card-image"
            />
            <div className="img-text">
              <p className="text">September 25-27, 2024 </p>
            </div>
            <p
              className="overlay-text"
              style={{
                marginLeft: "8px",
                fontWeight: "bold",
                fontFamily: "Helvitica",
              }}
            >
              IBAAS-IIM 2024 Conference & Exhibition
            </p>
          </Link> */}
          {/* <Link
            key={2}
            to={"/industrial_events/North_American_Biopolymer_Summit"}
            target="_blank"
            className="news-card"
            style={{ height: "300px", width: "30%", marginBottom: "20px" }}
          >
            <img
              src={eventimage9}
              alt="North American Biopolymer Summit"
              className="post-card-image"
            />
            <div className="img-text">
              <p className="text">October 02-03, 2024 </p>
            </div>
            <p
              className="overlay-text"
              style={{
                marginLeft: "8px",
                fontWeight: "bold",
                fontFamily: "Helvitica",
              }}
            >
              North American Biopolymer Summit
            </p>
          </Link> */}
          {/* <Link
            key={2}
            to={"/industrial_events/SEPAWA_CONGRESS"}
            target="_blank"
            className="news-card"
            style={{ height: "300px", width: "30%", marginBottom: "20px" }}
          >
            <img
              src={eventimage8}
              alt="SEPAWA CONGRESS"
              className="post-card-image"
            />
            <div className="img-text">
              <p className="text">October 16-18, 2024 </p>
            </div>
            <p
              className="overlay-text"
              style={{
                marginLeft: "8px",
                fontWeight: "bold",
                fontFamily: "Helvitica",
              }}
            >
              SEPAWA CONGRESS
            </p>
          </Link> */}
          {/* <Link
            key={2}
            to={"/industrial_events/India_Chem_2024"}
            target="_blank"
            className="news-card"
            style={{ height: "300px", width: "30%", marginBottom: "20px" }}
          >
            <img
              src={eventimage4}
              alt="India_Chem_2024"
              className="post-card-image"
            />
            <div className="img-text">
              <p className="text">October 17-19, 2024 </p>
            </div>
            <p
              className="overlay-text"
              style={{
                marginLeft: "8px",
                fontWeight: "bold",
                fontFamily: "Helvitica",
              }}
            >
              India Chem 2024
            </p>
          </Link> */}
          {/* ------------20/09-------- */}
          {/* <Link
            key={2}
            to={
              "/industrial_events/6th_Annual_Pharma_Manufacturing_&_Automation_Convention_2024"
            }
            target="_blank"
            className="news-card"
            style={{ height: "300px", width: "30%", marginBottom: "20px" }}
          >
            <img
              src={eventimage16}
              alt="6th Annual Pharma Manufacturing & Automation Convention 2024"
              className="post-card-image"
            />
            <div className="img-text">
              <p className="text">October 17-18, 2024 </p>
            </div>
            <p
              className="overlay-text"
              style={{
                marginLeft: "8px",
                fontWeight: "bold",
                fontFamily: "Helvitica",
              }}
            >
              6th Annual Pharma Manufacturing & Automation Convention 2024
            </p>
          </Link> */}

          {/* ------------20/09-------- */}
          {/* <Link
            key={2}
            to={
              "/industrial_events/Pharma_Manufacturing_&_Automation_Excellence_Awards_2024"
            }
            target="_blank"
            className="news-card"
            style={{ height: "300px", width: "30%", marginBottom: "20px" }}
          >
            <img
              src={eventimage15}
              alt="Pharma Manufacturing & Automation Excellence Awards 2024"
              className="post-card-image"
            />
            <div className="img-text">
              <p className="text">October 18, 2024 </p>
            </div>
            <p
              className="overlay-text"
              style={{
                marginLeft: "8px",
                fontWeight: "bold",
                fontFamily: "Helvitica",
              }}
            >
              Pharma Manufacturing & Automation Excellence Awards 2024
            </p>
          </Link> */}
          {/* ------------20/09-------- */}
          {/* <Link
            key={2}
            to={
              "/industrial_events/The_2nd_European_Sustainable_Plastics_Summit_2024"
            }
            target="_blank"
            className="news-card"
            style={{ height: "300px", width: "30%", marginBottom: "20px" }}
          >
            <img
              src={eventimage11}
              alt="The 2nd European Sustainable Plastics Summit 2024"
              className="post-card-image"
            />
            <div className="img-text">
              <p className="text">October 22-23,2024</p>
            </div>
            <p
              className="overlay-text"
              style={{
                marginLeft: "8px",
                fontWeight: "bold",
                fontFamily: "Helvitica",
              }}
            >
              The 2nd European Sustainable Plastics Summit 2024
            </p>
          </Link> */}
          {/* -------------- */}
          {/* <Link
            key={2}
            to={"/industrial_events/India_Refining_Summit_2024"}
            target="_blank"
            className="news-card"
            style={{ height: "300px", width: "30%", marginBottom: "20px" }}
          >
            <img
              src={eventimage18}
              alt="India Refining Summit 2024"
              className="post-card-image"
            />
            <div className="img-text">
              <p className="text">October 23-24,2024</p>
            </div>
            <p
              className="overlay-text"
              style={{
                marginLeft: "8px",
                fontWeight: "bold",
                fontFamily: "Helvitica",
              }}
            >
              India Refining Summit 2024
            </p>
          </Link> */}
          {/* <Link
            key={2}
            to={"/industrial_events/RE_Source_2024"}
            target="_blank"
            className="news-card"
            style={{ height: "300px", width: "30%", marginBottom: "20px" }}
          >
            <img
              src={eventimage14}
              alt="RE-Source 2024"
              className="post-card-image"
            />
            <div className="img-text">
              <p className="text">October 24-25,2024</p>
            </div>
            <p
              className="overlay-text"
              style={{
                marginLeft: "8px",
                fontWeight: "bold",
                fontFamily: "Helvitica",
              }}
            >
              RE-Source 2024
            </p>
          </Link> */}
          {/* ------- */}
          {/* <Link
            key={1}
            to={`/industrial_events/ADIPEC`}
            target="_blank"
            className="news-card"
            style={{ height: "300px", width: "30%", marginBottom: "20px" }}
          >
            <img
              src={adipec}
              alt="oil and corrosion"
              className="post-card-image"
            />
            <div className="img-text">
              <p className="text">November 4-7, 2024 </p>
            </div>
            <p
              className="overlay-text"
              style={{
                marginLeft: "8px",
                fontWeight: "bold",
                fontFamily: "Helvitica",
              }}
            >
              ADIPEC , Abu Dhabi, UAE
            </p>
          </Link> */}
          {/* event 20 */}
          {/* <Link
            key={1}
            to={`/industrial_events/CHEMLOG_India_2024`}
            target="_blank"
            className="news-card"
            style={{ height: "300px", width: "30%", marginBottom: "20px" }}
          >
            <img
              src={eventimage20}
              alt="CHEMLOG India 2024"
              className="post-card-image"
            />
            <div className="img-text">
              <p className="text">November 14, 2024 </p>
            </div>
            <p
              className="overlay-text"
              style={{
                marginLeft: "8px",
                fontWeight: "bold",
                fontFamily: "Helvitica",
              }}
            >
              CHEMLOG India 2024
            </p>
          </Link> */}
        </div>
      </div>
    </div>
  );
};

export default Events;
